<template>
	<div class="d-flex justify-content-between align-items-center">
		<div class="label-section">
			<label v-if="label" :for="id" class="form-label me-1">
				{{ label }}
			</label>
			<MDBTooltipZ
				v-if="fieldOptions.tooltip?.length"
				v-model="tooltip"
				:options="{ strategy: 'fixed', placement: 'right-end' }"
				class="form-label cursor-pointer"
				:max-width="fieldOptions.tooltipOptions?.maxWidth"
				direction="right"
			>
				<template #reference>
					<IconBadge icon="info-circle" :options="{ classList: ['ms-1'] }" />
				</template>
				<template #tip>{{ fieldOptions.tooltip }}</template>
			</MDBTooltipZ>
		</div>
		<MDBTooltip
			v-if="props.field.aiBuilder"
			v-model="aiBuilderTooltip"
			:options="{ strategy: 'fixed' }"
		>
			<template #reference>
				<IconBadge
					icon="fas.sparkles"
					class="text-primary ms-auto cursor-pointer flex-shrink-0"
					@click="showAiBuilderModal = true"
				/>
			</template>
			<template #tip>
				{{ props.field?.aiBuilder?.tooltipText || "Edit with AI" }}
			</template>
		</MDBTooltip>
	</div>

	<div
		class="position-relative"
		:class="{
			'input-group':
				props.field.prepend ||
				props.field.prependButton ||
				props.field.append ||
				props.field.appendButton ||
				props.field.aiBuilder ||
				false,
		}"
	>
		<a
			v-if="
				props.field.prependButton !== undefined &&
				props.field.prependButton.download
			"
			class="input-group-prepend input-group-text"
			:class="props.field.prependButton.classList || []"
			target="_blank"
			:download="props.field.prependButton.download || false"
			:disabled="
				props.field.prependButton.disabled || props.field.disabled || false
			"
			:href="
				typeof props.field.prependButton.href === 'function'
					? props.field.prependButton.href(props.field, dataModel)
					: props.field.prependButton.href
			"
		>
			<FontAwesomeIcon
				v-if="props.field.prependButton.icon"
				:icon="lookupIcon(props.field.prependButton.icon, 'fas')"
			/>
		</a>
		<Link
			v-else-if="props.field.prependButton !== undefined"
			class="input-group-prepend input-group-text"
			:class="props.field.prependButton.classList || []"
			:disabled="
				props.field.prependButton.disabled || props.field.disabled || false
			"
			:href="
				typeof props.field.prependButton.href === 'function'
					? props.field.prependButton.href(props.field, dataModel)
					: props.field.prependButton.href || ''
			"
			:as="props.field.prependButton.type || 'a'"
			:method="props.field.prependButton.method || 'get'"
		>
			<FontAwesomeIcon
				v-if="props.field.prependButton.icon"
				:icon="lookupIcon(props.field.prependButton.icon, 'fas')"
			/>
		</Link>
		<span
			v-if="props.field.prepend"
			class="input-group-prepend input-group-text"
			>{{ props.field.prepend }}</span
		>
		<div v-if="props.field.type === 'number'" class="arrows">
			<IconBadge
				:options="{ classList: ['cursor-pointer'] }"
				icon="caret-up"
				@click="onInput(parseInt(localValue) + 1)"
			/>
			<IconBadge
				:options="{ classList: ['cursor-pointer'] }"
				icon="caret-down"
				@click="onInput(parseInt(localValue) - 1)"
			/>
		</div>
		<MDBInputZ
			:id="inputId"
			:model-value="localValue"
			:required="computedRequired"
			:type="props.type"
			autocomplete="off"
			:maxlength="
				props.type === 'text' &&
				(fieldOptions.maxLength ?? props.field.maxLength)
			"
			:readonly="
				typeof props.field.readonly == 'function'
					? props.field.readonly(props.field, dataModel.value)
					: props.field.readonly || props.readonly || falsefield
			"
			:disabled="
				typeof props.field.readonly == 'function'
					? props.field.readonly(props.field, dataModel.value)
					: props.field.readonly || props.readonly || false
			"
			:class="props.field.classList || props.classList || []"
			:form-outline="false"
			wrapper-class="form-floating"
			:options="fieldOptions"
			:is-validated="props.isValidated"
			:is-valid="props.isValid"
			:invalid-feedback="props.invalidFeedback"
			class="input-group"
			@update:model-value="onInput"
		/>

		<span v-if="props.field.append" class="append input-group-text">{{
			typeof props.field.append == "function"
				? props.field.append(props.field, dataModel)
				: props.field.append
		}}</span>
		<a
			v-if="
				props.field.appendButton !== undefined &&
				props.field.appendButton.download
			"
			class="input-group-prepend input-group-text"
			:class="props.field.appendButton.classList || []"
			target="_blank"
			:download="props.field.appendButton.download || false"
			:disabled="
				props.field.appendButton.disabled || props.field.disabled || false
			"
			:href="
				typeof props.field.appendButton.href === 'function'
					? props.field.appendButton.href(props.field, dataModel)
					: props.field.appendButton.href
			"
		>
			<FontAwesomeIcon
				v-if="props.field.appendButton.icon"
				:icon="lookupIcon(props.field.appendButton.icon, 'fas')"
			/>
		</a>
		<Link
			v-else-if="props.field.appendButton !== undefined"
			class="input-group-prepend input-group-text"
			:class="props.field.appendButton.classList || []"
			:disabled="
				props.field.appendButton.disabled || props.field.disabled || false
			"
			:href="
				typeof props.field.appendButton.href === 'function'
					? props.field.appendButton.href(props.field, dataModel)
					: props.field.appendButton.href
			"
			:as="props.field.appendButton.type || 'a'"
			:method="props.field.appendButton.method || 'get'"
		>
			<FontAwesomeIcon
				v-if="props.field.appendButton.icon"
				:icon="lookupIcon(props.field.appendButton.icon, 'fas')"
			/>
		</Link>
		<div
			v-if="props.type === 'text' && fieldOptions.displayCharacterCount"
			class="smaller text-end text-secondary-10"
		>
			<span class="text-black">{{ localValue?.length }}</span>
			<span v-if="fieldOptions.maxLength ?? props.field.maxLength">
				/ {{ fieldOptions.maxLength ?? props.field.maxLength }}
			</span>
			Characters
		</div>

		<AiFormFieldBuilder
			v-if="props.field?.aiBuilder"
			v-model:is-open="showAiBuilderModal"
			:system-prompt="props.field.aiBuilder?.systemPrompt"
			:field="props.field"
			:current-value="{ value: localValue }"
			:output-format="{
				value: `{value for this option}${fieldOptions?.maxLength ? `: (max length ${fieldOptions?.maxLength} characters)` : ''}`,
			}"
			@save="handleAiBuilderSave"
		/>
	</div>
</template>

<script setup>
import { ref, computed, defineModel } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { lookupIcon } from "@/Composables/useAwesomeIcons"
import IconBadge from "@/Components/IconBadge.vue"
import { Link } from "@inertiajs/vue3"
import MDBInputZ from "@/Components/Mod/MDBOverride/MDBInputZ.vue"
import { storeToRefs } from "pinia"
import MDBTooltipZ from "../MDBOverride/MDBTooltipZ.vue"
import AiFormFieldBuilder from "./AiFormFieldBuilder.vue"
import { MDBTooltip } from "mdb-vue-ui-kit"
const props = defineProps({
	field: {
		type: Object,
		default: () => ({}),
	},
	type: {
		type: String,
		default: "text",
	},
	label: {
		type: [String, Function],
		default: "",
	},
	required: {
		type: Boolean,
		default: false,
	},
	readonly: {
		type: Boolean,
		default: false,
	},
	classList: {
		type: Array,
		default: () => [],
	},
	dataStore: {
		type: Object,
		default: undefined,
	},
	errorMessages: {
		type: Array,
		default: () => [],
	},
	options: {
		type: Object,
		default: undefined,
	},
	isValidated: { type: Boolean },
	isValid: { type: Boolean },
	invalidFeedback: { type: String, default: undefined },
})

const fieldOptions = computed(() => props.options ?? props.field.options ?? {})

const tooltip = ref(null)

const inputId = computed(() => {
	return props.field.id || props.field.name
})

const { dataModel } = props.dataStore ? storeToRefs(props.dataStore) : {}
const fieldValue = defineModel({
	type: String,
	default: "",
})
let computedRequired = computed(() => {
	return typeof props.field.required === "function"
		? props.field.required(props.field, dataModel.value)
		: typeof props.required === "function"
			? props.required(props.field, dataModel.value)
			: props.field.required || props.required || false
})
const localValue = computed(() => {
	let fieldName = props.field.name || props.name || null
	return props.dataStore && fieldName
		? props.dataStore.getModelValue({
				column: props.field.column || null,
				name: fieldName,
			})
		: fieldValue.value ||
				(typeof props.field.default == "function"
					? props.field.default(props.field, dataModel?.value)
					: props.field.default) ||
				props.field.content ||
				props.default ||
				""
})
let label = computed(() => {
	if (typeof props.field.label === "function") {
		return props.field.label(props.field, dataModel.value)
	} else if (typeof props.label === "function") {
		return props.label(props.field, dataModel.value)
	} else {
		return props.field.label || props.label
	}
})
const onInput = (value) => {
	let fieldName = props.field.name || props.name || null
	fieldValue.value = value
	if (props.dataStore && fieldName) {
		setDataModel(
			{
				name: fieldName,
				column: props.field.column || null,
			},
			value,
		)
	}
}
let setModelTimeout = null
const setDataModel = (field, value) => {
	if (setModelTimeout) {
		clearTimeout(setModelTimeout)
	}
	setModelTimeout = setTimeout(() => {
		props.dataStore.setModelValue(field, value, true)
	}, 100)
}

const showAiBuilderModal = ref(false)
const aiBuilderTooltip = ref(false)
const handleAiBuilderSave = (payload) => {
	try {
		let receivedJson = JSON.parse(payload)

		if (receivedJson.value) {
			const maxLength = fieldOptions.value?.maxLength
			let tempVal = receivedJson.value
			if (tempVal && maxLength && tempVal.length > maxLength) {
				tempVal = tempVal.slice(0, maxLength)
			}
			onInput(tempVal)
		}
	} catch (error) {
		console.error("Error parsing AI response:", error)
	}
	showAiBuilderModal.value = false
}
</script>
<style scoped>
.arrows {
	position: absolute;
	transform: translateY(-50%);
	top: 50%;
	right: 20px;
	height: 50%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.arrows > * {
	justify-self: center;
	align-self: center;
}

.input-group-prepend {
	margin-top: auto;
}
</style>
