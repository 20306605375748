import markdownit from "markdown-it"
import DOMPurify from "dompurify"
export const isJsonMessage = (message) => {
	let jsonMarkerMatch =
		message.includes("```json") ||
		message.startsWith("[") ||
		message.startsWith("{")

	if (jsonMarkerMatch) {
		return true
	}

	return false
}

export const parseJsonMessage = (message) => {
	message = message.trim()
	let jsonMatch = message.match(/```json\n([\s\S]*?)```/)
	if (!jsonMatch) {
		if (message.startsWith("[") || message.startsWith("{")) {
			try {
				return JSON.parse(message)
			} catch (e) {
				console.error("Failed to parse JSON:", e)
				return null
			}
		}
		return null
	}

	try {
		const parsed = JSON.parse(jsonMatch[1])
		return Array.isArray(parsed) ? parsed : [parsed]
	} catch (e) {
		console.error("Failed to parse JSON:", e)
		return null
	}
}

export const getTextBeforeJson = (message) => {
	if (message.trim().startsWith("{") || message.trim().startsWith("[")) {
		return ""
	}
	const parts = message.split("```json")
	if (parts.length === 1) {
		return message.trim()
	}
	return parts[0].trim()
}

export const getTextAfterJson = (message) => {
	const parts = message.split("```")
	if (parts.length < 3) {
		return ""
	}
	return parts[parts.length - 1].trim()
}

export const formatMarkdown = (text) => {
	const rendered = markdownit().render(text)
	const withoutPTags = rendered.replace(/<p>(.*?)<\/p>/g, "$1")
	return DOMPurify.sanitize(withoutPTags)
}
export const formatMessage = (message) => {
	return formatMarkdown(message)
}

export const sanitizeMessage = (message) => {
	return DOMPurify.sanitize(message)
}

export const parseAIResponse = (responseText) => {
	try {
		if (typeof responseText !== "string" || responseText.trim() === "") {
			throw new Error("Empty or invalid response")
		}

		let jsonString = responseText

		// If the response contains a code block labeled "json", extract its content.
		if (responseText.includes("```json")) {
			const startIndex = responseText.indexOf("```json")
			const afterMarkerIndex = startIndex + "```json".length

			// Instead of checking whether the response ends with "```",
			// we look for the outermost closing marker by using lastIndexOf.
			const closingIndex = responseText.lastIndexOf("```")
			if (closingIndex > afterMarkerIndex) {
				// Extract everything between the opening marker and the outer closing marker.
				jsonString = responseText
					.substring(afterMarkerIndex, closingIndex)
					.trim()
			} else {
				// No proper closing marker found: use everything after the opening marker...
				jsonString = responseText.substring(afterMarkerIndex).trim()
				// ...then trim any extra text by extracting only up to the last valid closing character.
				if (jsonString.length > 0) {
					const firstChar = jsonString[0]
					if (firstChar === "{") {
						const lastCurly = jsonString.lastIndexOf("}")
						if (lastCurly !== -1) {
							jsonString = jsonString.substring(0, lastCurly + 1)
						}
					} else if (firstChar === "[") {
						const lastBracket = jsonString.lastIndexOf("]")
						if (lastBracket !== -1) {
							jsonString = jsonString.substring(0, lastBracket + 1)
						}
					}
				}
			}
		}

		try {
			// Try parsing as-is first.
			let result = JSON.parse(jsonString)

			// If the result is an array, wrap it in the expected format.
			if (Array.isArray(result)) {
				result = { options: result }
			}

			// Validate that the result contains at least one expected key
			const hasValidKeys = [
				"chat",
				"chat_end",
				"options",
				"label",
				"value",
			].some(
				(key) =>
					result.hasOwnProperty(key) &&
					result[key] !== null &&
					result[key] !== undefined,
			)

			if (!hasValidKeys) {
				throw new Error("Response missing required keys")
			}

			// If we have label/value, wrap it in the options format
			if (result.label && result.value) {
				result = {
					chat: "I apologize, but I had trouble formatting my response. Could you try again?",
					options: [result],
				}
			}

			// Clean up nested JSON in the chat property
			if (result && result.chat) {
				const parts = result.chat.split(/```json/)
				if (parts.length > 1) {
					result.chat = parts[0].trim()
				}
			}

			// Check for empty chat after all processing
			if (!result.chat || result.chat.trim() === "") {
				result.chat =
					"I apologize, but I had trouble formatting my response. Could you try again?"
			}

			return result
		} catch (error) {
			// Attempt to fix common JSON formatting issues.

			let fixedJsonBlock = jsonString
				.replace(/,(\s*[}\]])/g, "$1")
				.replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3')
				.replace(/(?:\\{1,2})&/g, "&")
				.replace(/(?:\\{1,2})>/g, ">")
				.replace(/(?:\\{1,2})</g, "<")
				.replace(/(?:\\{1,2})=/g, "=")
				.replace(/(?:\\{1,2})~/g, "~")
				.replace(/(?:\\{1,2})`/g, "`")
				.replace(/(?:\\{1,2})\^/g, "^")

			// This regex finds any JSON string literal (taking into account escaped quotes)
			// and replaces literal newline characters with "\n".
			fixedJsonBlock = fixedJsonBlock.replace(
				/("(?:(?:\\.|[^"\\])*)")/g,
				(match) => {
					return match.replace(/\n/g, "\\n")
				},
			)

			try {
				const result = JSON.parse(fixedJsonBlock)

				if (result.chat == "") {
					result.chat =
						"I apologize, but I had trouble formatting my response. Could you try again?"
				}
				return result
			} catch (error) {
				return {
					chat: jsonString,
				}
			}
		}
	} catch (error) {
		console.error("Error parsing AI response:", error)
		console.error("Raw response:", responseText)
		return [
			{
				chat: "I apologize, but I had trouble formatting my response. Could you try again?",
			},
		]
	}
}
