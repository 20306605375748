<template>
	<Teleport to="body">
		<MDBModal
			id="ai-form-builder-modal"
			v-model="localIsOpen"
			@update:model-value="updateIsOpen"
		>
			<MDBModalHeader>
				<MDBModalTitle>
					<h5>AI Builder</h5>
				</MDBModalTitle>
			</MDBModalHeader>
			<MDBModalBody class="p-0 d-flex flex-column" style="height: 60vh">
				<AiChat
					ref="chatContainer"
					:messages="chatHistory"
					:loading="isLoading"
					:disabled="isLoading"
					:status-message="
						isLoading && pendingContext != props.uniqueInputId
							? 'Please wait while your AI assistant is busy working on another chat.'
							: ''
					"
					@send-message="handleSendMessage"
					@save-message="handleSave"
					@clear-chat="handleClearChat"
					@keydown.enter.prevent
				/>
			</MDBModalBody>
		</MDBModal>
	</Teleport>
</template>

<script setup>
import { ref, watch, nextTick, computed, reactive, onMounted } from "vue"
import {
	MDBModal,
	MDBModalBody,
	MDBModalHeader,
	MDBModalTitle,
} from "mdb-vue-ui-kit"
import AiChat from "@/Components/Chat/AiChat.vue"
import { parseAIResponse } from "@/Utils/aiChatMessageHelpers"
import axios from "@/Services/http"
import { usePage } from "@inertiajs/vue3"

const props = defineProps({
	isOpen: {
		type: Boolean,
		required: true,
	},
	systemPrompt: {
		type: String,
		default: null,
	},
	field: {
		type: Object,
		required: true,
	},
	currentValue: {
		type: [Object, String, Array, Number, Boolean, null],
		required: false,
		default: null,
	},

	tabsList: {
		type: Array,
		required: false,
		default: () => [],
	},
	outputFormat: {
		type: Object,
		required: false,
		default: () => {},
	},
	uniqueInputId: {
		type: String,
		default: "default",
	},
})

const emit = defineEmits(["update:isOpen", "save"])

// Local state
const localIsOpen = ref(props.isOpen)
const chatHistories = ref(new Map())
const adminAiSessionIds = ref(new Map())
const isLoading = ref(false)
const pendingMessageId = ref(null)
const pendingContext = ref(null)

const chatContainer = ref(null)
// Computed property for field change information
const fieldChangeInfo = computed(() => {
	if (props.field.aiBuilder.fullForm) {
		return ""
	}
	if (!props.currentValue) {
		return ""
	}

	let fieldChangeString = ""

	if (props.field.label) {
		fieldChangeString += `The field you are helping build is labeled "${props.field.label}". `
	}

	fieldChangeString += "The current value of this field is:\n\n"
	fieldChangeString += "```json\n"
	fieldChangeString +=
		typeof props.currentValue === "string"
			? props.currentValue
			: JSON.stringify(props.currentValue)
	fieldChangeString += "\n```\n\n"

	fieldChangeString += `Your role is to help the user either:\n`
	fieldChangeString += `1. Edit/improve the existing text shown above based on their request, or\n`
	fieldChangeString += `2. Create entirely new content if they want to start fresh\n\n`
	fieldChangeString += `Please determine which approach the user wants based on their message. `
	fieldChangeString += `If they want to edit the existing text, reference it in your response and explain your changes. `
	fieldChangeString += `If they want new content, you can disregard the existing text.\n\n`
	fieldChangeString += `IMPORTANT: Regardless of whether you are editing or creating new content, `
	fieldChangeString += `your final response must still follow the JSON format specified above, `
	fieldChangeString += `with the content properly placed within the "options" array.`

	return fieldChangeString
})

const outputFormatComputed = computed(() => {
	const includeLabelField = props.field.aiBuilder.includeLabel

	if (
		!props.field.aiBuilder.outputFormat &&
		includeLabelField &&
		!props.outputFormat?.label
	) {
		return {
			value: props.outputFormat?.value,
			label: "{summary or label for this options}",
		}
	}
	return props.field.aiBuilder.outputFormat
		? props.field.aiBuilder.outputFormat
		: props.outputFormat
})

// Sync modal state with parent
watch(
	() => props.isOpen,
	(newValue) => {
		localIsOpen.value = newValue
		if (newValue) {
			// Scroll to bottom when modal opens
			nextTick(() => {
				chatContainer.value.scrollToBottom()
			})
		}
	},
)

const updateIsOpen = (value) => {
	emit("update:isOpen", value)
}

const roleDetails = computed(() => {
	return (
		props.field.aiBuilder.role ||
		"Your role is to interact with the user to understand their requirements and assist them in writing text for a form field."
	)
})

const toneDetails = computed(() => {
	return (
		props.field.aiBuilder.tone ||
		"Maintain a professional yet approachable tone while engaging with users to understand their requirements."
	)
})

const finalSystemPrompt = computed(() => {
	const baseFormat = JSON.stringify(
		{
			chat: "Friendly intro text to the user",
			chat_end: "Friendly outro text to the user",
			options: [outputFormatComputed.value],
		},
		null,
		4,
	)
	const multipleFormat = JSON.stringify(
		{
			chat: "Friendly intro text to the user",
			chat_end: "Friendly outro text to the user",
			options: [outputFormatComputed.value, outputFormatComputed.value],
		},
		null,
		4,
	)

	let prompt = `${roleDetails.value}

${toneDetails.value}

When it's time to provide an output example, your responses should ALWAYS follow this structure using valid JSON:
\`\`\`json
${baseFormat}
\`\`\`

Example with multiple options:
\`\`\`json
${multipleFormat}
\`\`\`

Ensure that newlines, control characters, and any markdown formatting (such as bold or italics) included within JSON string values is properly escaped so that the output remains valid JSON. Do not include markdown code blocks (e.g., using triple backticks) within the “chat”, “chat_end”, or “options” arrays.

For example if you are including markdown within the "chat" or "chat_end" fields, you should escape it like this:
{
    "example": "This text uses escaped markdown for bold: \\*\\*bold text\\*\\* and for italics: \\_\\_italic text\\_\\_."
}

`

	// Add field-specific format details if provided
	if (props.field.aiBuilder.outputFormatDetails) {
		prompt += `\n\nAdditional format details: ${props.field.aiBuilder.outputFormatDetails}`
	}

	// Add custom examples if provided
	if (props.field.aiBuilder.examples?.length) {
		prompt += "\n\n*Additional examples:"
		props.field.aiBuilder.examples.forEach((example) => {
			prompt += `\n${typeof example === "string" ? example : JSON.stringify(example)}`
		})
	}

	// Add field change context if relevant
	if (fieldChangeInfo.value) {
		prompt += `\n${fieldChangeInfo.value}`
	}

	return prompt
})

// Computed to get current chat history based on uniqueInputId
const chatHistory = computed({
	get: () => chatHistories.value.get(props.uniqueInputId) || [],
	set: (newValue) => chatHistories.value.set(props.uniqueInputId, newValue),
})

// Helper to get/set adminAiSessionId for current context
const getAdminAiSessionId = () =>
	adminAiSessionIds.value.get(props.uniqueInputId)
const setAdminAiSessionId = (id) =>
	adminAiSessionIds.value.set(props.uniqueInputId, id)

const handleSendMessage = async (message) => {
	event.preventDefault()

	// Set the pending context
	pendingContext.value = props.uniqueInputId
	isLoading.value = true

	// Initialize chat history for this context if it doesn't exist
	if (!chatHistories.value.has(pendingContext.value)) {
		chatHistories.value.set(pendingContext.value, [])
	}

	// Get the current context's chat history
	const currentContextHistory =
		chatHistories.value.get(pendingContext.value) || []

	// Add user message to correct context
	chatHistories.value.set(pendingContext.value, [
		...currentContextHistory,
		{ role: "user", text: { chat: message } },
	])

	// Add loading message to correct context
	pendingMessageId.value = Date.now()
	chatHistories.value.set(pendingContext.value, [
		...chatHistories.value.get(pendingContext.value),
		{
			role: "model",
			loading: true,
			id: pendingMessageId.value,
		},
	])

	try {
		const response = await axios.post(
			route("dashboard.admin-ai-session-messages", {
				team: usePage().props.currentTeam.slug,
			}),
			{
				form_field_type: props.field.type,
				userMessage: message,
				systemPrompt: finalSystemPrompt.value,
				admin_ai_session_id: adminAiSessionIds.value.get(pendingContext.value),
				project: usePage().props.project ? usePage().props.project.slug : null,
			},
		)

		setAdminAiSessionId(response.data.admin_ai_session_id)

		// Replace loading message with actual response in correct context
		const currentHistory = chatHistories.value.get(pendingContext.value) || []
		const messageIndex = currentHistory.findIndex(
			(msg) => msg.id === pendingMessageId.value,
		)
		if (messageIndex !== -1) {
			const updatedHistory = [...currentHistory]
			updatedHistory[messageIndex] = {
				role: "model",
				text: parseAIResponse(response.data.prompt),
			}
			chatHistories.value.set(pendingContext.value, updatedHistory)
		}
	} catch (error) {
		// Replace loading message with error in correct context
		const currentHistory = chatHistories.value.get(pendingContext.value) || []
		const messageIndex = currentHistory.findIndex(
			(msg) => msg.id === pendingMessageId.value,
		)
		if (messageIndex !== -1) {
			const updatedHistory = [...currentHistory]
			updatedHistory[messageIndex] = {
				role: "model",
				text: {
					chat:
						error.response?.data?.error ||
						"Error: Unable to process your request. Please try again.",
				},
				action:
					error.response?.data?.error === "History limit reached"
						? "clear"
						: undefined,
			}
			chatHistories.value.set(pendingContext.value, updatedHistory)
		}
	} finally {
		isLoading.value = false
		pendingMessageId.value = null
		pendingContext.value = null
	}
}

const handleSave = (messageText) => {
	emit("save", messageText)
	localIsOpen.value = false
}

const handleClearChat = () => {
	chatHistories.value.delete(props.uniqueInputId)
	adminAiSessionIds.value.delete(props.uniqueInputId)
}

onMounted(() => {})

const fieldLabels = reactive({})
</script>

<style scoped>
div#ai-form-builder-modal {
	z-index: 999999999;
}
</style>
